<template>
	<div v-if="app.leaderboard" class="leaderboard" :class="app.leaderboard.city.slug">
		<div class="bg" v-hammer:tap="close"></div>
		<div class="content">

			<div class="header">
				<div class="city">{{ app.leaderboard.city.document.title }}</div>
				<div class="title">Tableau des scores</div>
			</div>

			<div class="game-type">
				<span v-if="activeGame && activeGame.document && activeGame.document.title" class="name">{{ activeGame.document.title }}</span>
				<div v-if="activeGame && activeGameIndex > 0" class="arrow prev" v-hammer:tap="set_prev_game"></div>
				<div v-if="activeGame && activeGameIndex < app.leaderboard.city.clientGames.length - 1" class="arrow next" v-hammer:tap="set_next_game"></div>
			</div>

			<div class="scores" v-scrollbar:scrollArrows :class="{ large: !tabs_visible }">
				<div>
					<div v-for="(score, index) in scores" :key="index" class="score">
						<div class="left">
							<div class="rank">
								<img v-if="index >= 0 && index <= 2" :src="medals[index]" alt="Médaille">
								<span v-else>{{ index }}</span>
							</div>
							<div v-if="score.user && score.user.profile && score.user.profile.surname" class="name">{{ score.user.profile.surname }}</div>
							<div v-else class="name">-</div>
						</div>
						<div class="right">
							<div class="time"><span v-if="score.time">{{ score.time }}</span></div>
							<div class="value">{{ score.score }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="tabs" :class="{ small: !tabs_visible }">
				<div v-for="city in cities" :key="city.id" class="tab" :class="[ city.slug, { active: app.leaderboard.city.id === city.id }]" v-hammer:tap="() => set_city(city)">
					<img :src="city.icon">
				</div>
			</div>

			<div v-show="loading" class="loader">
				<ui-icon name="loader" :size="64" />
			</div>
			
			<div class="close" v-hammer:tap="close">
				<ui-icon name="close" :size="16" />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'leaderboard',
	data() {
		return {
			loading: false,
			scores: []
		}
	},
	computed: {
		...mapGetters([ 'app', 'world' ]),
		tabs_visible() {
			return this.app.leaderboard.display_tabs !== undefined ? this.app.leaderboard.display_tabs : true
		},
		cities() {
			if (this.tabs_visible) {
				return this.world.cities.map(city => {
					return { ...city, icon: this.$medias.find({
						clientMedias: city.clientMedias,
						tags: ['city_icon']
					}) }
				})
			}
			return []
		},
		medals() {
			return [ 'gold', 'silver', 'bronze' ].map(medal => this.$medias.find({ tags: [ 'medal', medal ] }) )
		},
		activeGameIndex() {
			return this.app.leaderboard.game_id ? this.app.leaderboard.city.clientGames.findIndex(g => g.id === this.app.leaderboard.game_id) : 0
		},
		activeGame() {
			return this.app.leaderboard.city.clientGames.length ? this.app.leaderboard.city.clientGames[this.activeGameIndex] : 0
		},
		activeGameId() {
			return this.activeGame ? this.activeGame.id : null
		}
	},
	watch: {
		activeGameId: {
			async handler(id) {
				this.scores = []
				this.loading = true
				if (id) {
					const result = await Apollo.query('LEADERBOARD', { name: 'game_by_score', params: '{"game_id": "' + id + '"}' })
					if (result && result.length) {
						this.scores = result.map(r => {
							const seconds = r.time ? Math.floor(r.time / 1000) : 0
							const time = seconds ? ('0' + Math.floor(seconds / 60)).slice(-2) + ':' + ('0' + Math.floor(seconds % 60)).slice(-2) : ''
							return {
								...r, 
								score: ('000' + (r.score < 1 ? r.score : 1) * 100).slice(-3),
								time
							}
						})
					}
					this.loading = false
				}
			},
			immediate: true
		}
	},
	methods: {
		set_city(city) {
			this.$store.commit('EDIT_APP_STATE', { leaderboard: { ...this.app.leaderboard, city, game_id: null } })
		},
		set_prev_game() {
			if (this.activeGameIndex > 0) {
				const game = this.app.leaderboard.city.clientGames[this.activeGameIndex - 1]
				if (game) this.$store.commit('EDIT_APP_STATE', { leaderboard: { ...this.app.leaderboard, game_id: game.id } })
			}
		},
		set_next_game() {
			if (this.activeGameIndex < this.app.leaderboard.city.clientGames.length - 1) {
				const game = this.app.leaderboard.city.clientGames[this.activeGameIndex + 1]
				if (game) this.$store.commit('EDIT_APP_STATE', { leaderboard: { ...this.app.leaderboard, game_id: game.id } })
			}
		},
		close() {
			this.$store.commit('EDIT_APP_STATE', { leaderboard: false })
		}
	},
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.leaderboard
	&.leaderboard-enter-active, &.leaderboard-leave-active
		transition 0.25s easeOutQuart
		.content
			transition 0.25s easeOutQuart
	&.leaderboard-enter, &.leaderboard-leave-to
		opacity 0
		.content
			transform translateY(-24px)

.leaderboard
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 50%)
	.content
		width calc(100% - 40px)
		height calc(100% - 40px)
		max-width 400px
		max-height 750px
		background-color #fff
		border-radius 16px
		box-shadow 0px 10px 6px alpha(#000, 30%)

		.header
			height 80px
			flex center center
			flex-direction column
			background-color #60C2B5
			border-radius 16px 16px 0 0
			color #fff
			text-align center
			text-transform uppercase
			font-weight 500
			.city
				margin 0 0 8px 0
				font-size 1.6rem
				line-height 1em
			.title
				font-size 2.4rem
				line-height 1em

		.game-type
			height 64px
			flex center center
			background-color #EEFBF9
			color #60C2B5
			text-transform uppercase
			text-align center
			font-size 2.4rem
			line-height 1em
			font-weight 500
			.arrow
				position absolute
				top calc(50% - 20px)
				width 40px
				height 40px
				background #60C2B5 url('../assets/img/chevron-right.svg') center center no-repeat
				background-size 32px 32px
				border-radius 50%
				cursor pointer
				transition transform 0.1s easeOutQuart
				&:hover
					transform scale(1.1)
				&:active
					transform scale(1)
				&.prev
					left -16px
					background-image url('../assets/img/chevron-right.svg')
					transform rotate(180deg)
					&:hover
						transform scale(1.1) rotate(180deg)
					&:active
						transform scale(1) rotate(180deg)
				&.next
					right -16px
					background-image url('../assets/img/chevron-right.svg')
		
		.scores
			height calc(100% - 224px)
			&.large
				height calc(100% - 160px)
			.score
				padding 8px 32px 8px 16px
				flex space-between center
				border-top 1px solid #ccc
				color #949292
				font-size 1.8rem
				line-height 1em
				.left, .right
					flex center center
					gap 8px
				.rank
					height 40px
					width 40px
					flex center center
					font-size 2rem
					line-height 1
					img
						height 100%
				.value
					margin 0 0 0 8px
					color #EAB847
					font-size 2rem
					line-height 1
				.time
					font-variant-numeric tabular-nums
					font-family 'MonoSpatial'
		
		.tabs
			height 80px
			border-top 16px solid #60C2B5
			flex center center
			border-radius 0 0 16px 16px
			&.small
				height 0
			.tab
				width 100%
				height 64px
				flex center center
				cursor pointer
				transition 0.2s easeOutQuart
				&:first-child
					border-radius 0 0 0 16px
				&:last-child
					border-radius 0 0 8px 0
				&.active
					transform translateY(8px)
					border-radius 0 0 16px 16px
					z-index 1
				img
					width 75%
					height 75%
		
		.close
			position absolute
			right -8px
			top -8px
			width 32px
			height 32px
			flex center center
			background-color #01053B
			border-radius 50%
			box-shadow 0 0 0 3px #fff
			cursor pointer
			transition transform 0.1s easeOutQuart
			&:hover
				transform scale(1.05)
			&:active
				transform scale(1)
		
		.loader
			absolute 0
			flex center center
		


</style>
