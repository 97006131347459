<template>
	<div class="learn-more-content">
		<div class="content">
			<div class="scroll" v-scrollbar:scrollArrows>
				<div>
					<div class="scroll-content">
						<div class="bg"></div>
						<div v-if="currentLearnMore.title" class="title"><span v-html="currentLearnMore.title"></span></div>
						<div v-if="currentLearnMore.media" class="image">
							<img :src="currentLearnMore.media" :alt="currentLearnMore.title">
						</div>
						<ui-text-content v-if="currentLearnMore.html" :html="currentLearnMore.html" class="wrap"/>
						<div class="buttons">
							<div v-if="lastQuestion" class="button" v-hammer:tap="()=> nextQuestion()">Bilan<span class="arrow"></span></div>
							<div v-else class="button" v-hammer:tap="()=> nextQuestion()">Prochaine question <span class="arrow"></span></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Sounds from '@/js/utils/sounds'

import UiTextContent from '@/components/ui/ui-text-content'

export default {
	name: "learn-more-content",
	components: { UiTextContent },
	props: [ 'currentLearnMore', 'lastQuestion' ],
	data() {
		return {}
	},
	methods: {
		nextQuestion() {
			Sounds.play('button')
			this.$emit('next')
		}
	}
}
</script>

<style scoped lang="stylus">
	@import '../../../assets/css/variables'

	#app.mobile
		.learn-more-content
			border-radius 0


	.learn-more-content
		absolute 0
		transition transform 0.25s easeOutQuart
		color #237096
		overflow hidden
		-webkit-mask-image -webkit-radial-gradient(white, black)
		border-radius 32px
		z-index 2
		&.learn-more-content-enter, &.learn-more-content-leave-to
			.content
				transform translateX(-100%)
		.content
			height 100%
			width 100%
			background-color #fff
			transition transform 0.25s easeOutQuart
			> .scroll
				height 100%
				.scroll-content
					padding 32px
					overflow-wrap break-word
			.bg
				position absolute
				left -25%
				right 0
				top -40px
				width 150%
				height 250px
				background-color #7ED5FF
				transform rotate(-10deg)
			.title
				margin 0 0 16px 0
				font-size 4rem
				font-weight 700
				color #fff
				line-height 48px
				> span
					text-decoration none
					background linear-gradient(to top, #4A91B4 0%, #4A91B4 50%, transparent 50.01%) no-repeat left bottom / 0 100%
					background-size 100% 100%
			.image
				text-align center
				margin 0 0 16px 0
				img
					max-width 100%
					height auto
					margin 0 auto
			>>> .ui-text-content
				margin 0 0 32px 0
				font-size 2.4rem
				line-height 32px
				a
					text-decoration underline
					&:hover
						text-decoration none
			.buttons
				padding 32px 0
				border-top 4px solid #237096
				.button
					flex center center
					width 100%
					height 48px
					border-radius 16px
					background #4A91B4
					box-shadow 0px 3px 6px alpha(#000, 50%)
					cursor pointer
					color #fff
					font-size 2.4rem
					&:active
						transform translateY(3px)
						box-shadow none
					.arrow
						width 24px
						height 24px
						margin 0 0 0 16px
						background url('../../../assets/img/double-arrow-light.svg') center center no-repeat
						background-size 100%
</style>
