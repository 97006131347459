<template>
	<div class="charade">
		<div class="charade-content" v-if="current_question">
			
			<transition name="charade-bg">
				<div v-show="translateX > 0" class="charade-bg correct" key="correct">
					<transition name="icon" mode="out-in">
						<div class="icon" :key="current_question.id"></div>
					</transition>
				</div>
			</transition>
			<transition name="charade-bg">
				<div v-show="translateX < 0" class="charade-bg wrong" key="wrong">
					<transition name="icon" mode="out-in">
						<div class="icon" :key="current_question.id"></div>
					</transition>
				</div>
			</transition>
			
			<transition name="question" mode="out-in">
				<div class="question-wrap" :key="current_question.id">
					<div class="question" :style="{ transform: 'translateX(' + translateX + 'px) rotate(' + rotate + 'deg)' }" :class="{ transition, right: translateX > 0, left: translateX < 0 }">
						<div class="header">
							<div class="score">{{ results.correct }}<div class="icon correct"></div></div>
							<div class="title">Charade {{ question_index + 1 }}</div>
							<div class="score"><div class="icon wrong"></div>{{ results.wrong }}</div>
							<div class="timer">
								<div class="timer-progress" :class="{ started: timer_progress_percent > 0 }" :style="{ transform: 'translateX(-' + (timer_progress_percent) + '%)' }"></div>
							</div>
						</div>

						<div class="question-text" v-html="current_question_text"></div>

						<div class="arrow left">
							<div class="icon"></div>
							<div class="text">Je ne<br>trouve pas</div>
						</div>
						<div class="arrow right">
							<div class="icon"></div>
							<div class="text">Bonne<br>réponse</div>
						</div>
					</div>
				</div>
			</transition>

			<div class="overlay" v-hammer:panstart="panstart" v-hammer:pan="pan" v-hammer:panend="panend"></div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'

const minmax = (value, min, max) => {
	if (value > max) return max
	if (value < min) return min
	return value
}

export default {
	name: 'charade',
	data() {
		return {
			pan_ready: false,
			timer: false,
			time: 0,
			question_index: 0,
			transition: false,
			translateX: 0,
			rotate: 0,
			results: {
				correct: 0,
				wrong: 0
			}
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'user', 'world' ]),
		questions() {
			const questions = getValueOnPath(this.game, 'step.questions')
			return (questions && questions.length) ? questions : []
		},
		current_question() {
			return (this.questions && this.questions[this.question_index]) ? this.questions[this.question_index] : false
		},
		timer_progress_percent() {
			const timer_total = parseInt(getValueOnPath(this.current_question, 'document.timer')) * 2
			if (timer_total) return 100 - this.time * 100 / timer_total
			return 0
		},
		current_question_text() {
			if (this.current_question) {
				const text = this.current_question.contentTexts.find((q) => {
					if (q.tags) return q.tags.includes('question')
					return false
				})
				if (text && text.html) return text.html
			}
			return ''
		},
	},
	watch: {
		'app.tuto'(newValue, oldValue) {
			if (!newValue && oldValue) {
				this.start_game()
			}
		}
	},
	methods: {
		start_game() {
			if (!this.app.tuto) this.init_timer()
			this.pan_ready = true
		},
		async end_game() {
			this.clear_timer()
			this.$store.dispatch('complete_current_step')
		},
		init_timer() {
			this.clear_timer()
			if (this.current_question && this.current_question.document && this.current_question.document.timer) {
				this.time = parseInt(this.current_question.document.timer) * 2
				this.timer = setInterval(this.update_timer, 500)
			}
		},
		clear_timer() {
			if (this.timer) {
				clearInterval(this.timer)
				this.timer = false
			}
		},
		update_timer() {
			if (this.time <= 0) {
				this.end_game()
			} else
				this.time--
		},
		select_answer(answer) {
			this.$store.dispatch('trivia_send_answer', { question_id: this.current_question.id, answers_id: [ answer.id ] })
			this.question_index = (this.question_index >= this.questions.length - 1) ? 0 : this.question_index + 1
			setTimeout(() => {
				this.translateX = 0
				this.rotate = 0
				setTimeout(() => {
					this.pan_ready = true
				}, 500)
			}, 1250)
		},
		panstart() {
			if (this.pan_ready) {
				this.transition = false
				this.translateX = 0
				this.rotate = 0
			}
		},
		pan(e) {
			if (this.pan_ready) {
				this.translateX = minmax(e.deltaX, -64, 64)
				this.rotate = minmax(e.deltaX * 10 / 64, -10, 10)
			}
		},
		panend() {
			if (this.pan_ready) {
				if (this.translateX > 32 || this.translateX < -32) {
					this.pan_ready = false
					const answer = this.translateX > 0 ? this.current_question.answers[0] : this.current_question.answers[1]
					if (this.translateX > 0/*answer.score === 1*/)
						this.results.correct++
					else
						this.results.wrong++
					this.select_answer(answer)
				} else {
					this.transition = true
					this.translateX = 0
					this.rotate = 0
				}
			}
		},
	},
	mounted() {
		this.start_game()
	},
	beforeDestroy() {
		this.clear_timer()
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.charade
		.charade-content
			.charade-bg
				border-radius 0
			.question-wrap .question
				border-radius 0

.charade
	height 100%
			
	.charade-content
		height 100%
		color #fff
		font-size 2.4rem
		.charade-bg
			absolute 0
			border-radius 32px
			&.charade-bg-leave-active
				transition opacity 0.25s easeOutQuart 0.25s
			&.charade-bg-leave-to
				opacity 0
			&.wrong
				background linear-gradient(to bottom, #A53644, #AB5C8C)
				.icon
					background-image url('../../assets/img/charade-false-icon.svg')
			&.correct
				background linear-gradient(to bottom, #32A888, #5CAC6A)
				.icon
					background-image url('../../assets/img/charade-true-icon.svg')
			.icon
				position absolute
				left calc(50% - 100px)
				top calc(50% - 100px)
				width 200px
				height 200px
				background center center no-repeat
				background-size contain
				&.icon-leave-active
					transition 1.65s easeOutQuart
				&.icon-leave-to
					transform translateY(-64px)
					opacity 1
		.header
			position absolute
			left 0
			top 0
			right 0
			padding 0 16px
			height 64px
			flex space-between center
			.score
				flex center center
				gap 8px
				.icon
					width 32px
					height 32px
					background center center no-repeat
					background-size contain
					&.wrong
						background-image url('../../assets/img/charade-false.svg')
					&.correct
						background-image url('../../assets/img/charade-true.svg')
			.timer
				position absolute
				left 0
				right 0
				top 64px
				height 6px
				width 100%
				background-color alpha(#fff, 25%)
				overflow hidden
				.timer-progress
					position absolute
					left 0
					top 0
					height 100%
					width 100%
					// background-color darken(#F16064, 5%)
					background-color #fff
					&.started
						transition transform 0.5s linear

		.question-wrap
			absolute 0
			&.question-enter-active
				transition transform 0.25s easeInOutSine 1s, opacity 0.25s easeInOutSine 1s
			&.question-leave-active
				transition 0.25s easeOutQuart !important
				.question
					transition 0.25s easeOutQuart !important
			&.question-enter
				transform translateY(-64px) scale(1.025) !important
				opacity 0 !important
			&.question-leave-to
				opacity 0
				.question
					&.right
						transform translateX(96px) rotate(12deg) !important
					&.left
						transform translateX(-96px) rotate(-12deg) !important
			.question
				absolute 0
				flex center center 
				padding 32px
				background linear-gradient(to bottom, #F6CF4B, #FB6E19)
				border-radius 32px
				transform-origin bottom center
				&.transition
					transition 0.25s easeOutQuart
				.question-text
					font-size 3.2rem
					text-align center
				.arrow
					position absolute
					bottom 32px
					max-width 40%
					opacity 0.5
					line-height 1em
					&.left
						left 32px
						.icon
							background-image url('../../assets/img/charade-arrow-left.svg')
							left 16px
							transform-origin 0px 96px
							animation idle-left 1s easeOutQuart infinite alternate
					&.right
						right 32px
						text-align right
						.icon
							background-image url('../../assets/img/charade-arrow-right.svg')
							right 16px
							transform-origin 96px 96px 
							animation idle-right 1s easeOutQuart infinite alternate
					.icon
						position absolute
						bottom calc(100% - 24px)
						width 96px
						height 96px
						background center center no-repeat
						background-size contain
						@keyframes idle-left
							0%
								transform rotate(-4deg) translateX(-8px)
							100%
								transform rotate(0deg)
						@keyframes idle-right
							0%
								transform rotate(4deg) translateX(8px)
							100%
								transform rotate(0deg)

		.overlay
			absolute 0

</style>
