<template>
	<div class="matching">
		<div class="matching-content">
			
			<div class="head-part" id="matching-header">
				<div class="head-part-content">
					<div class="timeline">
						<div class="bar">
							<div class="progress" :class="{ error: timeline_error }" :style="{ transform: 'translate3D(' + progress + '%, 0, 0)' }"></div>
						</div>
						<div class="players">
							<div class="player ia" :style="{ left: opponent_percent + '%' }">
								<div class="avatar" v-html="opponent_avatar"></div>
							</div>
							<div class="player me" :style="{ transform: 'translate3D(' + progress + '%, 0, 0)' }">
								<div class="avatar"></div>
							</div>
						</div>
						<div class="medals">
							<div v-for="(m, index) in medals" :key="index" class="medal" :class="[ m.medal, m.state ]" :style="{ left: (100 - m.score * 100) + '%' }"></div>
						</div>
					</div>
					<div class="time">{{ current_time }}</div>
				</div>
			</div>
			<transition-group name="matchable" class="matchable-elements" tag="div" appear>
				<matchable-element
					v-for="matchable_element in visible_matchable_elements"
					v-if="matchable_element.visible"
					:matchable_element="matchable_element"
					:key="matchable_element.id"
					@toggle-active="toggle_active"
				/>
			</transition-group>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'
import ClockWorker from 'worker-loader!@/js/web-workers/steps.worker'
import MatchableElement from '@/components/game/matching/matchable-element'
import Sounds from '@/js/utils/sounds'

const timer = new ClockWorker

export default {
	name: 'matching',
	data() {
		return {
			timeline_error: false,
			nb_errors: 0,
			progress: 0,
			current_time: 0,
			duration: 60000,
			timestamps: []
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'world' ]),
		current_step() {
			return getValueOnPath(this.game, 'step')
		},
		matchable_elements() {
			let sets = this.current_step.sets.data
			if (sets && sets.length > 0) {
				let matches = sets.reduce((acc, val) => {
					let answers = getValueOnPath(val, 'matchableElements')
					acc = [...acc, ...answers]
					return acc
				}, [])
				return matches
			}
			return []
		},
		visible_matchable_elements() {
			return this.matchable_elements.filter((m) => { return m.visible }).sort((a, b) => a.order - b.order)
		},
		opponent_percent() {
			return (1 - this.current_step.simulator.score) * 100
		},
		opponent_avatar() {
			if (this.$route.params.is_challenge) {
				return this.$medias.find({ tags: [ 'avatar', 'challenge-avatar' ] })
			} else {
				const slug = getValueOnPath(this, 'world.current.city.slug')
				if (slug) {
					const tags = [ slug, 'avatar' ]
					return this.$medias.find({ tags })
				}
			}
			return null
		},
		medals() {
			if (this.game.current_client_game) {
				const medals = [ 'gold', 'silver', 'bronze' ]
				return this.game.current_client_game.clientRewards
					.filter(g => g.clientLootItem.tags && g.clientLootItem.tags.includes('medal'))
					.map((g) => {
						const progress = 1 - (this.progress / 100)
						const score = g.thresholds[0].score
						const state = (progress < score + 0.10 && progress > score - 0.03) ? 'blink' : (progress < score) ? 'inactive' : 'active'
						return { medal: g.clientLootItem.tags.find(t => medals.includes(t)), score, state }
					})
			}
			return []
		}
	},
	watch: {
		'app.tuto'(newValue, oldValue) {
			if (!newValue && oldValue) this.start_game()
		},
		'game.ui.matching_error'(newValue) {
			if (newValue) this.nb_errors++
		},
		'nb_errors'(newValue) {
			if (newValue) {
				this.timeline_error = true
			}
		}
	},
	methods: {
		start_game() {
			if (!this.app.tuto) {
				this.duration = Math.ceil(parseInt(this.current_step.timeAllowed) / 1000)
				const time_lost_per_mistake = Math.ceil(this.current_step.timePenalty / 1000)
				let error_viewed = false
				timer.onmessage = ((e) => {
					this.current_time = Math.floor((e.data.remainingSteps - this.nb_errors * time_lost_per_mistake) / 2)
					if (this.current_time < 0) this.current_time = 0
					this.progress = 100 - ((e.data.remainingSteps - this.nb_errors * time_lost_per_mistake) * 100 / (this.duration * 2))
					if (this.progress >= 100) {
						this.$store.commit('EDIT_GAME_STATE', {
							player_score: (this.duration - this.current_time),
							opponent_score: this.current_step.simulator.time / 1000
						}),
						this.timestamps.push(Date.now())
						const time = (this.timestamps[this.timestamps.length - 1] - this.timestamps[this.timestamps.length - 2])
						this.$store.dispatch('matching_send_match', { matchable_elements: [], time  }).then(() => {
							this.$store.dispatch('complete_current_step')
						})
					}
					if (this.timeline_error && error_viewed) {
						this.timeline_error = false
						error_viewed = false
					}
					if (this.timeline_error && !error_viewed) error_viewed = true
				})
				timer.postMessage({ cmd: 'setRemainingSteps', data: this.duration * 2 })
				timer.postMessage({ cmd: 'setTotalSteps', data: this.duration * 2 })
				timer.postMessage({ cmd: 'setInterval', data: 500 })
				timer.postMessage({ cmd: 'start' })
				this.timestamps.push(Date.now())
			}
			this.$store.dispatch('draw_matching_elements')
		},
		async toggle_active(data) {
			this.$store.dispatch('toggle_active_matchable_element', { matchable_element: data.matchable_element })
			Sounds.play('button')
			let actives = this.visible_matchable_elements.filter((m) => { return m.active && !m.checked })
			if (actives.length === 2) {
				this.timestamps.push(Date.now())
				const time = (this.timestamps[this.timestamps.length - 1] - this.timestamps[this.timestamps.length - 2])
				await this.$store.dispatch('matching_send_match', { matchable_elements: actives, time })
				let checked = this.visible_matchable_elements.filter((m) => { return m.checked })
				if (checked.length === this.visible_matchable_elements.length) {
					this.$store.dispatch('draw_matching_elements')
					this.$nextTick(() => {
						if (!this.visible_matchable_elements.length) {
							timer.postMessage({ cmd: 'stop' })
							this.$store.commit('EDIT_GAME_STATE', {
								player_score: (this.duration - this.current_time),
								opponent_score: this.current_step.simulator.time / 1000
							})
							this.$store.dispatch('complete_current_step')
						}
					})
				}
			}
		}
	},
	mounted() {
		this.start_game()
	},
	beforeDestroy() {
		if (timer) timer.postMessage({ cmd: 'stop' })
	},
	components: { MatchableElement }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#matching-header
	&.highlight
		z-index 2
		opacity 1
		&:before
			opacity 0.15

.matching
	height 100%
	.matching-content
		height 100%
		border-radius 32px
		padding 24px 32px 16px 32px
		.head-part
			height 64px
			margin 0 -32px 80px -32px
			padding 0 32px
			background-color #fff
			font-size 2rem
			flex center center 
			&:before
				content ''
				position absolute
				left 0
				right 0
				top 91px
				height 4px
				background-color #fff
			.head-part-content
				height 24px
				padding 4px
				width 100%
				flex center center 
				border 1px solid red
				border-radius 8px
				.time
					height @height
					width 64px
					line-height @height
					text-align center
				.timeline
					width calc(100% - 64px)
					height 100%
					border-radius 4px
					.bar
						absolute 0
						overflow hidden
						border-radius 4px
						.progress
							absolute 0
							transition transform 0.5s linear
							background-color yellow
							&.error
								background-color #D64747
					.medals
						absolute 0
						.medal
							position absolute
							top -9px
							width 32px
							height 32px
							transform translateX(-16px)
							background #fff center center no-repeat
							background-size contain
							box-shadow 0 0 0 4px #fff
							border-radius 50%
							opacity 1
							transition opacity 0.2s linear
							&.gold
								background-image url(../../assets/img/medal-gold-light.svg)
							&.silver
								background-image url(../../assets/img/medal-silver-light.svg)
							&.bronze
								background-image url(../../assets/img/medal-bronze-light.svg)
							&.inactive
								opacity 0.25
							&.blink
								animation 0.2s blink linear infinite alternate
					.players
						absolute 0
						.player
							position absolute
							top 0
							left 0
							width 100%
							transition transform 0.5s linear
							.avatar
								top 48px
								width 40px
								height 40px
								margin-left -20px
								border-radius 50%
								&:before
									content ''
									position absolute
									left calc(50% - 2px)
									top -48px
									width 4px
									height 48px
									background-color darken(red, 25%)
							&.ia
								.avatar
									left 1px
									background-color blue
									box-shadow 0 0 0 4px #fff
									background center center no-repeat
									background-size contain
									&:before
										height (48px - 4px)
									>>> svg
										width 100%
										height 100%
							&.me
								.avatar
									left 0
									background url('../../assets/img/avatars/player.svg') center center no-repeat
									background-size contain
									&:before
										background-color dark
			
		.matchable-elements
			height 'calc(100% - %s)' % (64px + 80px)
			display grid
			grid 1fr 1fr 1fr 1fr / 1fr 1fr
			grid-column-gap 16px
			grid-row-gap 16px
			.matchable-element
				&.matchable-enter-active
					transition 0.5s easeOutQuart
					for i in 0..8
						&:nth-child({i})
							transition-delay (0.075s * i)
				&.matchable-enter
					transform translateY(-24px)
					opacity 0


@keyframes progress-down
	0%
		background-position 0 center
	100%
		background-position 80px center

@keyframes blink
	0%
		opacity 0.25
	100%
		opacity 0.75

</style>
