<template>
	<div class="settings">
		<div class="bg" v-hammer:tap="close"></div>
		<div class="content">

			<div class="contribution">Avec la contribution financière de :</div>
			<div class="logo"><img src="@/assets/img/logo-oqlf-colors.svg" alt="OQLF"></div>
			
			<div class="main-title">PARAMÈTRES</div>

			<div class="item">
				<div class="label">Volume</div>
				<div class="value"><ui-slider :value="app.settings.sounds" slug="sounds" @change="set_setting" /></div>
			</div>

			<div class="close" v-hammer:tap="close"></div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiSlider from '@/components/ui/ui-slider'

export default {
	name: 'settings',
	data() {
		return {
			sounds: [
				{ value: 0, label: 0 },
				{ value: 1, label: 1 },
				{ value: 2, label: 2 },
				{ value: 3, label: 3 },
				{ value: 4, label: 4 },
				{ value: 5, label: 5 },
				{ value: 6, label: 6 },
				{ value: 7, label: 7 },
				{ value: 8, label: 8 },
				{ value: 9, label: 9 },
				{ value: 10, label: 10 },
			],
		}
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	methods: {
		set_setting(data) {
			const settings = { ...this.app.settings }
			settings[data.slug] = data.value
			this.$store.commit('EDIT_APP_STATE', { settings })
		},
		close() {
			this.$store.commit('EDIT_APP_STATE', { settings_visible: false })
		}
	},
	components: { UiSlider }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


.settings
	&.settings-enter-active, &.settings-leave-active
		transition 0.25s easeOutQuart
	&.settings-enter, &.settings-leave-to
		opacity 0
		.content
			transform translateY(-20px)
			

.settings
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	display flex
	align-items center
	justify-content center
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 50%)
	.content
		width calc(100% - 32px)
		max-width 420px
		padding 32px
		background-color #fff
		border-radius 8px
		box-shadow 0px 10px 6px alpha(#000, 30%)
		transition 0.25s easeOutQuart
		
		.contribution
			margin 0 0 32px 0
			text-align center
			font-size 2.2rem
			font-weight 500
		
		.logo
			width 100%
			margin 0 0 64px 0
			img
				width 100%
				height auto
		
		.main-title
			margin 0 0 16px 0
			text-align center
			font-size 2.4rem
			text-transform uppercase
			font-weight 500
		
		.item
			flex center center
			// margin 0 0 8px 0
			height (28px + 24px)
			padding 12px 16px
			border-radius 4px
			font-size 2rem
			line-height @height
			background-color alpha(dark, 10%)
			.label
				width 35%
			.value
				width 65%
				height 100%
				text-align right
				flex flex-end center
				>>> .ui-slider
					margin-right -8px
		
		.close
			position absolute
			right -8px
			top -8px
			width 40px
			height 40px
			background dark_blue url(../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid yellow_light
			cursor pointer
			&:active
				transform translateY(1px)


</style>
