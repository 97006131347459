<template>
	<div v-if="city" class="city" id="city" :class="city.slug" v-hammer:pan.vertical="(event)=> pan(event)" v-hammer:panend="(event)=> panend(event)">
		<div class="city-bg" v-hammer:tap="()=> close()"></div>
		<div class="city-content" ref="city">
			
			<div class="top">
				<city-stars :city="city" :arc="false" size="big" />
				<!-- <div class="stars" :class="'nb-' + city.clientGames.length">
					<div v-for="game in city.clientGames" :key="game.id" class="star" :class="{ full: has_gold_medal(game) }"></div>
				</div> -->
			</div>

			<div class="city-infos">
				<div class="name">{{ city.document.title }}</div>
				<div class="stamp">
					<city-badge :city="city" />
					<transition name="unlocked-badge">
						<city-badge v-if="is_unlocked" :city="city" :unlocked="true" />
					</transition>
					<div v-if="is_unlocked && trivia_game_tuto" class="opponent" v-html="opponent_avatar" v-hammer:tap="() => select_tuto_visible = true"></div>
				</div>
			</div>
			
			<div class="content">
				
				<div class="city-step step-travel" v-if="!is_unlocked">
					<div class="city-step-content" v-if="city.clientGames.length">
						<div class="main-button" v-hammer:tap="()=> travel()" :class="{ locked: !unlockable }">
							<div>Voyager</div>
							<div>{{ criteria }} <ui-icon :tags="[ 'resource', 'diamond', 'white' ]" :size="18" /></div>
						</div>
						<p v-if="!unlockable" class="not-enough-gold">Vous n'avez pas assez de diamants pour voyager.</p>
					</div>
					<div class="city-step-content" v-else>
						Aucun jeu disponible pour cette ville.
					</div>
					<div class="bottom">
						<div class="plane"></div>
						<div class="barcode"></div>
					</div>
				</div>

				<transition-group v-if="is_unlocked" name="game-button" tag="div" class="city-step step-games" appear>
					<game-button v-for="game in city.clientGames" :class="city.slug" :city="city" :game="game" :key="game.id" />
				</transition-group>

				<div v-if="is_unlocked" class="part-leaderboard">
					<ui-button color="white" v-hammer:tap="display_leaderboard">
						<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="18.405" height="16.998" viewBox="0 0 18.405 16.998">
							<defs>
								<clipPath id="clip-path">
								<rect width="18.405" height="16.998" fill="none"/>
								</clipPath>
							</defs>
							<g transform="translate(0 0)">
								<g transform="translate(0 0)" clip-path="url(#clip-path)">
								<path d="M18.4,3.885a2.674,2.674,0,0,0-.886-2.122,3.446,3.446,0,0,0-1.595-.727c.013-.206.022-.393.028-.553C15.964.116,15.731,0,15.438,0H2.966c-.293,0-.527.116-.513.483.005.16.014.347.027.554a3.437,3.437,0,0,0-1.594.726A2.674,2.674,0,0,0,0,3.885C0,6.913,3.153,8.848,5.15,9.218a5.315,5.315,0,0,0,2.038,1.459L6.97,17h4.464l-.218-6.321a5.315,5.315,0,0,0,2.038-1.459c2-.371,5.15-2.305,5.15-5.334m-17.569,0a1.854,1.854,0,0,1,.6-1.49,2.526,2.526,0,0,1,1.115-.519A15.685,15.685,0,0,0,4.334,8.093c-1.56-.639-3.5-2.117-3.5-4.208M14.07,8.093a15.681,15.681,0,0,0,1.787-6.217,2.526,2.526,0,0,1,1.115.519,1.854,1.854,0,0,1,.6,1.49c0,2.091-1.939,3.568-3.5,4.208" transform="translate(0 0)" fill="currentColor"/>
								</g>
							</g>
						</svg>
						Tableau des scores
					</ui-button>
				</div>
				
				<div class="city-step step-win" v-if="false">
					<div class="city-step-content">
						<div class="main-text">
							<p>Félicitations!</p>
							<p>Vous avez gagné</p>
							<p>350 <ui-icon :tags="[ 'resource', 'diamond', 'gray' ]" :size="18" /></p>
						</div>

						<div class="main-button big-height" v-hammer:tap="()=> get_reward()">Récolter</div>
					</div>
				</div>

			</div>

			<div class="close" v-if="!is_win" v-hammer:tap="()=> close()"></div>

		</div>

		<transition name="select-tuto">
			<div v-show="select_tuto_visible" class="select-tuto">
				<div class="select-tuto-bg" v-hammer:tap="() => select_tuto_visible = false"></div>
				<div class="select-tuto-content">
					<div class="opponent" v-html="opponent_avatar"></div>
					<div class="question">Quel tutoriel souhaites-tu revoir ?</div>
					<div class="answers">
						<game-button
							v-for="game in city.clientGames"
							:class="city.slug"
							:city="city"
							:game="game"
							:key="game.id"
							:force_tuto="true"
						/>
					</div>
					<div class="close" v-hammer:tap="() => select_tuto_visible = false"></div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CityBadge from '@/components/world/city-badge'
import GameButton from '@/components/world/game-button'
import CityStars from '@/components/world/city-stars'
import Sounds from '@/js/utils/sounds'

export default {
	name: 'city',
	data() {
		return {
			tuto_loading: false,
			select_tuto_visible: false
		}
	},
	props: {
		city: { default: null }
	},
	computed: {
		...mapGetters([ 'user', 'user_gold' ]),
		closable() {
			return !this.is_win
		},
		criteria() {
			let n = 0
			if (this.city.clientCriterias && this.city.clientCriterias.length) {
				let criteria = this.city.clientCriterias.find((c) => {
					return c.clientLootItem.slug == 'gold'
				})
				if (criteria) n = criteria.amount
			}
			return n
		},
		opponent_avatar() {
			const tags = [ this.city.slug, 'avatar' ]
			return this.$medias.find({ tags })
		},
		opponent_avatar_visible() {
			return this.user && this.user.achievement && this.user.achievement.tutorialJournal && this.user.achievement.tutorialJournal.find((j) => [ 'end_trivia_game', 'end_matching_game' ].includes(j.tutorialId))
		},
		is_unlocked() {
			if (!this.city.clientCriterias.length) return true
			let criteria = this.city.clientCriterias.find((c) => { return c.action == 'spend' && c.clientLootItem && c.clientLootItem.countable  && c.clientLootItem.slug == 'gold' })
			if (criteria && this.user.achievement.criteriasAchieved.find((achieve) => { return achieve.criteriaId == criteria.id })) return true
			return false
		},
		unlockable() {
			let criteria = this.city.clientCriterias.find((c) => { return c.clientLootItem.slug == 'gold' })
			return this.user_gold >= criteria.amount
		},
		is_win() {
			return false
		},
		trivia_game_tuto() {
			if (this.user.achievement && this.user.achievement.tutorialJournal) {
				return this.user.achievement.tutorialJournal.find((t) => t.tutorialId === 'trivia_game')
			}
			return false
		}
	},
	watch: {
		select_tuto_visible() {
			if (this.select_tuto_visible) Sounds.play('pop')
			this.tuto_loading = false
		}
	},
	methods: {
		// launch_city_tuto(game) {
		// 	if (!this.tuto_loading) {
		// 		this.tuto_loading = game.id
		// 		let slug = game.tags.find((t) => [ 'trivia_game', 'matching_game' ].includes(t))
		// 		if (slug) {
		// 			let tuto = this.user.achievement.tutorialJournal.find((t) => t.tutorialId === slug)
		// 			if (tuto) {
		// 				this.$store.commit('EDIT_TUTORIAL_JOURNAL', { tuto, status: 'not-done' })
		// 				this.$router.push({ name: 'game', params: { city_slug: this.city.slug, game_id: game.id } })
		// 			}
		// 		}
		// 	}
		// },
		display_leaderboard() {
			this.$store.commit('EDIT_APP_STATE', { leaderboard: {
				city: this.city,
				game_id: null,
				display_tabs: false
			} })
		},
		travel() {
			if (!this.is_unlocked && this.unlockable) {
				this.$store.dispatch('unlock_city', { city: this.city })

				// Lancemenent tuto
				const tuto_name = this.city.slug.replaceAll('-', '_').replaceAll(' ', '_')
				const user_tuto = this.user.achievement.tutorialJournal.find((j) => j.tutorialId === tuto_name)
				if (!user_tuto || user_tuto.status !== 'done') {
					setTimeout(() => {
						const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', this.city.slug ] })
						this.$store.commit('EDIT_APP_STATE', { tuto: { name: tuto_name, animations } })
					}, 500)
				}
			}
		},
		has_gold_medal(game) {
			let criteria = game.clientCriterias.find((c) => {
				return c.action == 'presence' && this.user.achievement.criteriasAchieved.find((achieved) => { return achieved.criteriaId == c.id })
			})
			if (criteria) return true
			return false
		},
		get_reward() {
			this.$store.dispatch('get_user')
		},
		close() {
			if (this.closable) {
				this.$router.push({ name: 'world' })
				
				// Si le joueur dispose de 4 achievements ou plus, on lance le concours
				if (this.user && this.user.achievement && this.user.achievement.criteriasAchieved && this.user.achievement.criteriasAchieved.length > 3 && this.user.achievement.tutorialJournal) {
					if (!this.user.achievement.tutorialJournal.find(t => t.tutorialId === 'concours_submitted' && t.status === 'done')) {
						const tuto = this.user.achievement.tutorialJournal.find((j) => j.tutorialId === 'concours')
						if (!tuto || tuto.status !== 'done') {
							setTimeout(() => {
								const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', 'bus_driver' ] })
								this.$store.commit('EDIT_APP_STATE', { tuto: { name: 'concours', animations } })
							}, 500)
						}
					}
				}
			}
		},
		pan(e) {
			if (this.closable) {
				let target = this.$refs.city
				let y = e.deltaY
				if (y > 0) y = 0
				target.style.transform = 'translate(0, ' + y + 'px)'
			}
		},
		panend(e) {
			if (this.closable) {
				let target = this.$refs.city
				let y = e.deltaY
				
				target.classList.add('panning')

				if (y < 0 && Math.abs(y) > target.clientHeight / 5) {
					target.style.transform = 'translate(0, -150%)'
					// target.style.opacity = 0
					this.close()
				} else  {
					// target.style.opacity = 1
					target.style.transform = 'translate(0, 0)'
				}

				setTimeout(() => {
					target.classList.remove('panning')
					target.style.transform = ''
				}, 250)
			}
		},
	},
	components: { CityBadge, GameButton, CityStars }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'
// @import '../../assets/css/city_color'


#app.mobile
	.city .city-content .content .city-step 
		.city-step-content
			margin-bottom 0
		.bottom
			display none

.city
	&.city-enter-active, &.city-leave-active
		transition 0.25s easeOutQuart
		.city-content
			transition 0.25s easeOutQuart
	&.city-enter, &.city-leave-to
		opacity 0
		.city-content
			transform translateY(-40px)
	// &.city-leave-to
	// 	opacity 0
	// 	.city-content
	// 		transform translateY(40px)

.game-button
	transition 0.25s easeOutQuart
	&:nth-child(1)
		transition-delay 0.1s
		>>> .medal
			transition-delay 0.5s !important
	&:nth-child(2)
		transition-delay 0.2s
		>>> .medal
			transition-delay 0.6s !important
	&:nth-child(3)
		transition-delay 0.3s
		>>> .medal
			transition-delay 0.7s !important
	&:nth-child(4)
		transition-delay 0.4s
		>>> .medal
			transition-delay 0.8s !important
	// &.game-button-active, &.game-button-leave-active
	// 	transition 0.25s easeOutQuart
	&.game-button-enter, &.game-button-leave-to
		opacity 0
		transform translateY(50%)
		>>> .medal
			opacity 0
			transform scale(3) !important
				

.city
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	height 100%
	width 100%
	display flex
	align-items center
	justify-content center
	&.tuto-highlight
		.city-bg
			display none
	.city-bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		background-color alpha(#000, 15%)
	.city-content
		width calc(100% - 40px)
		height calc(100% - 40px)
		
		max-width 400px
		max-height 650px
		
		background-color #fff
		border-radius 32px
		box-shadow 0px 10px 6px alpha(#000, 30%)
		// z-index 3
		&.panning
			transition transform 0.25s easeOutQuart
		
		.top
			height 80px
			background-color dark
			border-top-left-radius 32px
			border-top-right-radius 32px
						
		.city-infos
			height 240px
			display flex
			align-items center
			justify-content center
			align-content center
			flex-direction column
			// z-index 2
			.name
				margin 0 0 16px 0
				padding 0 4px
				font-size 3.7rem
				font-weight 700
				color dark
				text-transform uppercase
				text-align center
				line-height 32px
			.stamp
				width 100%
				height 164px
				display flex
				align-items center
				justify-content center
				>>> .city-badge
					position absolute
					left calc(50% - 82px)
					top 0
					width 164px
					height 164px
					background-color #fff
					&.unlocked-badge-enter-active, &.unlocked-badge-leave-active 
						transition 0.5s easeInQuart
					&.unlocked-badge-enter, &.unlocked-badge-leave-to 
						opacity 0
						transform scale(4) rotate(-15deg)
				.opponent
					position absolute
					left 'calc(50% + %s)' % round(164px / 2 - (74px / 2))
					top 10%
					width 64px
					height 64px
					border-radius 50%
					cursor pointer
					animation opponent-avatar 1.5s linear infinite
					>>> svg
						width 100%
						height 100%
		.content
			height calc(100% - (80px + 240px))
			.city-step
				height calc(100% - 80px)
				display flex
				align-items center
				justify-content center
				flex-direction column
				align-content center
				&.step-travel
					height 100%
				&.step-win
					.city-step-content
						margin-bottom 0
						transform translateY(-16px)
						.main-text
							margin-bottom 8px
				.city-step-content
					width 100%
					margin-bottom 100px
					text-align center
					.main-button
						width calc(100% - 64px)
						background-color dark
						margin 0 32px
						padding 8px 0
						box-shadow 0px 6px 6px alpha(#000, 30%)
						border-radius 8px
						cursor pointer
						text-align center
						font-size 2.4rem
						font-weight 500
						text-transform uppercase
						color #fff
						&.big-height
							padding 16px 0
						&:hover
							background-color darken(dark, 10%)
						&:active
							background-color darken(dark, 10%)
							transform translateY(2px)
							box-shadow 0px 4px 4px alpha(#000, 30%)
						&.locked
							cursor not-allowed
							background-color #c6c6c6
							&:hover, &:active
								transform none
								box-shadow 0px 6px 6px alpha(#000, 30%)
						> div
							display flex
							align-items center
							justify-content center
							.ui-icon
								margin 0 0 0 8px
					.not-enough-gold
						margin 16px 0 0 0
						padding 0 32px
						color red
						font-weight 700
						text-transform uppercase
						font-size 1.4rem
						line-height 16px
					.main-text
						color #949292
						font-size 2.4rem
						text-align center
						line-height 28px
						text-transform uppercase
				.bottom
					position absolute
					left 0
					right 0
					bottom 0
					height 100px
					display flex
					align-items center
					justify-content center
					flex-direction column
					.plane
						width 24px
						height 20%
						margin 0 0 8px 0
						background url(../../assets/img/plane.svg) center center no-repeat
						background-size auto 100%
						opacity 0.75
					.barcode
						width 100%
						height 40%
						background url(../../assets/img/barcode.svg) center center no-repeat
						background-size auto 100% 
						opacity 0.75

			.part-leaderboard
				position absolute
				left 0
				bottom 0
				right 0
				border-radius 0 0 32px 32px
				padding 16px 0
				background-color #60C2B5
				flex center center
				>>> .ui-button
					width calc(100% - 64px)
					.button
						gap 8px
						color #60C2B5
						font-weight 700
						text-transform uppercase
		
		.close
			position absolute
			right -8px
			top -8px
			width 40px
			height 40px
			background dark_blue url(../../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid #fff
			cursor pointer
			
			box-shadow 0 0 4px alpha(#000, 25%)
			&:active
				transform translateY(1px)

	.select-tuto
		absolute 0
		transition opacity 0.25s easeOutQuart
		&.select-tuto-enter, &.select-tuto-leave-to
			.select-tuto-bg
				opacity 0
			.select-tuto-content
				transform translate(-50%, -60%)
				opacity 0
		.select-tuto-bg
			absolute 0
			background-color alpha(#000, 75%)
			transition opacity 0.25s easeOutQuart
		.select-tuto-content
			position absolute
			left 50%
			top 50%
			width 100%
			max-width 330px
			transform translate(-50%, -50%)
			transition 0.25s easeOutQuart
			padding 32px
			background-color #fff
			border-radius 8px
			box-shadow 0 0 8px alpha(#000, 30%)
			text-align center
			&:before
				content ''
				position absolute
				left 56px
				top -40px
				width 64px
				height 64px
				background url(../../assets/img/bubble-bottom.svg) top left no-repeat
				background-size contain
				transform rotate(160deg)
			.opponent
				position absolute
				left calc(50% - 48px)
				top -120px
				width 96px
				height 96px
				&:after
					content ''
					absolute -2px
					border-radius 50%
					border 4px solid #fff
					// box-shadow inset 0 0 0 4px #fff
				>>> svg
					width 100%
					height 100%
			.question
				margin 0 0 24px 0
				font-size 2.4rem
				line-height 1em
			.answers
				>>> .game-button
					width 100%
					&:last-child
						margin 0
			.close
				position absolute
				right -12px
				top -12px
				width 40px
				height 40px
				background dark_blue url(../../assets/img/close.svg) center center no-repeat
				background-size 16px auto
				border-radius 50%
				border 4px solid yellow_light
				cursor pointer
				&:active
					transform translateY(1px)
			
</style>