<template>
	<div class="timer" :class="{ stopped: !playing, playing: playing }">{{ formated_current_time }}</div>
</template>

<script>
export default {
	name: 'ui-timer',
	data() {
		return {
			
		}
	},
	props: {
		time: { default: 0 },
		playing: { default: false },
	},
	computed: {
		formated_current_time() {
			if (this.time > 0) {
				let seconds = '0' + this.time
				return '00:' + seconds.substr(-2)
			}
			return '00:00'
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'



</style>
