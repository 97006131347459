<template>
	<div class="world">

		<world-map @load="set_world_loaded()" :show_cities="true" />

		<transition name="user-button" appear>
			<ui-button v-if="!app.tuto" class="user-button leaderboard" color="dark-blue" v-hammer:tap="()=> show_leaderboard()"><ui-icon name="trophy" :size="20" /></ui-button>
		</transition>
		<transition name="user-button" appear>
			<ui-button v-if="!app.tuto" class="user-button passport" color="dark-blue" v-hammer:tap="()=> show_passeport()">PASSEPORT</ui-button>
		</transition>
		<div v-if="!app.tuto && passeport_notification_visible" class="passeport-notification"></div>

		<div class="bottom-right">
			<transition name="user-button" appear>
				<ui-button v-if="!app.tuto" class="user-button settings" color="dark-blue" v-hammer:tap="()=> show_settings()"><ui-icon name="i-info" :size="20" /></ui-button>
			</transition>
			<transition name="user-button" appear>
				<ui-button v-if="!app.tuto" class="user-button money" color="dark-blue">{{ user_gold }} <ui-icon :tags="[ 'resource', 'diamond', 'white' ]" /></ui-button>
			</transition>
		</div>
		<div v-if="present_visible" class="present-button" v-hammer:tap="show_concours"><ui-icon name="present" :size="48" /></div>

		<transition name="passeport" appear>
			<passeport v-if="passeport_visible" />
		</transition>

		<transition name="save-progression" appear>
			<save-progression v-if="save_progression_visible" />
		</transition>

		<transition name="city" appear>
			<city v-if="world.current.city && !game.progression" :city="world.current.city" />
		</transition>

		<transition name="leaderboard">
			<leaderboard v-if="app.leaderboard" />
		</transition>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import City from '@/components/world/city'
import Passeport from '@/components/world/passeport'
import SaveProgression from '@/components/world/save-progression'
import WorldMap from '@/components/world/world-map'
import Leaderboard from '@/components/leaderboard'

export default {
	name: 'world',
	data() {
		return {
			passeport_notification_visible: false
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'world', 'user', 'user_gold' ]),
		passeport_visible() {
			return this.$route.name === 'passeport'
		},
		save_progression_visible() {
			return this.$route.name === 'passeport-save'
		},
		present_visible() {
			if (this.user && this.user.achievement && this.user.achievement.tutorialJournal && !this.user.achievement.tutorialJournal.find(t => t.tutorialId === 'concours_submitted' && t.status === 'done')) {
				return this.user.achievement.tutorialJournal.find(t => t.tutorialId === 'concours' && t.status === 'done')
			}
			return false
		}
	},
	watch: {
		'user.inventory'(newValue, oldValue) {
			if (oldValue && newValue && newValue.length > oldValue.length) {
				this.passeport_notification_visible = true
			}
		}
	},
	methods: {
		show_passeport() {
			if (this.user.email) {
				this.passeport_notification_visible = false
				this.$router.push({ name: 'passeport' })
			} else {
				this.$router.push({ name: 'passeport-save' })
			}
		},
		show_leaderboard() {
			this.$store.commit('EDIT_APP_STATE', { leaderboard: {
				city: this.world.cities[0],
				game_id: null
			} })
		},
		show_settings() {
			this.$store.commit('EDIT_APP_STATE', { settings_visible: true })
		},
		// reset_user() {
		// 	this.$store.dispatch('reset_user')
		// },
		set_world_loaded() {
			this.$store.commit('EDIT_APP_STATE', { main_loader: false })
			if (!this.user.email && !localStorage.getItem('tuto-login')) {
				const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', 'bus_driver' ] })
				this.$store.commit('EDIT_APP_STATE', { tuto: { name: 'login', animations } })
				localStorage.setItem('tuto-login', 'vu')
			}
		},
		show_concours() {
			const animations = this.$medias.findAllByTags({ tags: [ 'character_animation', 'bus_driver' ] })
			this.$store.commit('EDIT_APP_STATE', { tuto: { name: 'concours', animations } })
		}
	},
	components: { City, Passeport, WorldMap, SaveProgression, Leaderboard }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/variables'


#app.mobile
	.world .logo
		width 138px
		height 60px

.world .user-button
	&.user-button-enter-active, &.user-button-leave-active
		transition 0.25s easeOutQuart
	&.user-button-enter, &.user-button-leave-to
		opacity 0
		transform translateY(100%)


.world
	height 100%
	.bottom-right
		position absolute
		right 8px
		bottom 8px
		flex center center
		.user-button
			margin 0 8px 0 0
			&:last-child
				margin 0
	.user-button
		font-weight 700
		&.leaderboard
			position absolute
			bottom 8px
			left 8px
			width 40px
			>>> .button
				padding 0
				.ui-icon
					margin 0
		&.passport
			position absolute
			bottom 8px
			left 64px
			>>> .button
				padding-left 48px
				justify-content flex-start
			&:after
				content ''
				position absolute
				left 0
				top 0
				width (40px - 4px)
				height (40px - 4px)
				border-radius 50%
				background dark_blue url(../assets/img/planet.svg) center center no-repeat
				background-size 26px auto
				border 2px solid #fff
		&.money
			>>> .button
				justify-content flex-end
				cursor default
				&:active
					transform none
				*
					cursor default
		&.settings
			>>> .button
				padding 0
				width 64px
				justify-content center
				.ui-icon
					margin 0
		>>> .button
			height 40px
			padding 0 24px
			.ui-icon
				margin 0 0 0 8px
	.present-button
		position absolute
		right 16px
		bottom 64px
		flex center center
		width 48px
		height 48px
		// background-color #FBAB00
		// background-color red
		border-radius 50%
		cursor pointer
		// border 3px solid #fff
		animation 3s blink easeInOutBack infinite alternate
		@keyframes blink
			0%
				transform scale(1) rotate(0)
			40%, 44%, 48%, 52%, 56%
				transform scale(1.2) rotate(4deg)
			42%, 46%, 50%, 54%, 58%
				transform scale(1.2) rotate(-4deg)
			100%
				transform scale(1) rotate(0)
	.passeport-notification
		position absolute
		left 64px
		bottom 48px
		width 48px
		height 48px
		background url(../assets/img/passeport-notification.svg) bottom center no-repeat
		background-size contain
		animation bounce 1s linear infinite

@keyframes bounce
	0%, 100%
		transform none
	50%
		transform translateY(-8px)


</style>
