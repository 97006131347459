<template>
	<div v-if="question" class="trivia-question">

		<div class="bg"></div>

		<div class="top">
			<div class="title">Question {{ no }}</div>
		</div>

		<div class="content">
			<div v-if="current_question_type == 'text'" class="text" v-html="current_question_text"></div>
			<carousel
				v-if="current_question_type == 'carousel'"
				:slides="carousel_slides"
				@loaded="set_loaded(false)"
			/>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTimer from '@/components/ui/ui-timer'
import Carousel from '@/components/game/trivia/carousel'

export default {
	name: 'trivia-question',
	data() {
		return {
			loading: true
		}
	},
	props: {
		question: { default: false },
		no: { default: 1 }
	},
	computed: {
		...mapGetters([  ]),
		current_question_text() {
			let text = this.question.contentTexts.find((q) => {
				if (q.tags) return q.tags.includes('question')
				return false
			})
			if (text && text.html) return text.html
			return ''
		},
		current_question_type() {
			if (this.question.clientMedias && this.question.clientMedias.length > 1) return 'carousel'
			return 'text'
		},
		carousel_slides() {
			let slides = []

			let texts = this.question.contentTexts.filter((q) => {
				if (q.tags) return q.tags.includes('question')
				return false
			})
			let images = this.question.clientMedias.filter((q) => {
				if (q.tags) return q.tags.includes('question_carousel')
				return false
			})

			for (var i in texts) slides.push(texts[i])
			for (var i in images) slides.push(images[i])

			return slides
		}
	},
	watch: {
		question() {
			this.init()
		}
	},
	methods: {
		init() {
			this.images = []
			if (this.current_question_type == 'text') {
				this.set_loaded(false)
			} else {
				this.set_loaded(true)
			}
		},
		set_loaded(loading) {
			this.loading = loading
			if (!loading) setTimeout(() => { this.$emit('loaded') }, 500)
		}
	},
	mounted() {
		this.init()
	},
	components: { UiTimer, Carousel }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


.game
	&.rouge
		.trivia-question .bg:after
			background linear-gradient(to bottom, city_rouge, city_rouge_dark)
	&.bleu
		.trivia-question .bg:after
			background linear-gradient(to bottom, city_blue, city_blue_dark)

.trivia-question
	height 100%
	color #fff
	.bg
		position absolute
		left 0
		top 0
		right 0
		bottom 0
		// background url(../../../assets/img/map-bg.png) center center no-repeat
		background-size cover
		// &:after
		// 	content ''
		// 	position absolute
		// 	left 0
		// 	top 0
		// 	right 0
		// 	bottom 0
		// 	// background linear-gradient(to bottom, #F37F7F, #CF376B)
		// 	// background dark
		// 	opacity 0.9
	.top
		height (32px * 2)
		padding 16px 0
		text-align center
		font-size 2.8rem
	.content
		height calc(100% - 64px)
		padding 16px 0 16px 0
		display flex
		align-items center
		justify-content center
		text-align center
		font-size 2.2rem
		line-height 26px
		> .text
			padding 0 32px


</style>
