<template>
	<div v-if="city" class="city-badge" :class="{ unlocked: unlocked }">
		<img :src="badge_locked_icon" :alt="city.document.title">
		<!-- <div v-html="badge_locked_icon"></div> -->
	</div>
</template>

<script>
export default {
	name: 'city-badge',
	data() {
		return {
			
		}
	},
	props: {
		city: Object,
		unlocked: { default: false }
	},
	computed: {
		badge_locked_icon() {
			let locked = 'locked'
			if (this.unlocked) locked = 'unlocked'

			return this.$medias.find({
				clientMedias: this.city.clientMedias,
				tags: [ 'badge', locked ]
			})
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.city-badge
	display inline-block
	border-radius 50%
	margin 8px
	font-weight 700
	text-transform uppercase
	font-size 1.2rem
	color gray
	&.unlocked
		img
			color #c9c9c9
			flex center center
			// border-radius 50%
	img
		display block
		width 100%
		height 100%
			
</style>
