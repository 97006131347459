<template>
	<div class="passeport">
		<div class="passeport-bg" v-hammer:tap="()=> close()"></div>
		<div class="passeport-content" ref="passeport"> <!--  v-hammer:pan.horizontal="(event)=> pan(event)" v-hammer:swipe="(event)=> panend(event)" -->
			<div class="side"></div>
			<div class="bg"></div>
			<div class="pages">
				<div class="page"></div>
				<div class="page"></div>
				<div class="page"></div>
				<div class="page">
					<div class="page-content">
						<div class="user">
							<span v-if="user.document && user.document.surname">{{ user.document.surname }}</span>
						</div>
						<div class="stamps" v-scrollbar:scrollArrows>
							<div>
								<div class="stamps-content">
									<div v-for="city in world.cities" class="stamp" :key="city.id" v-hammer:tap="()=> goto_city(city)">
										<city-badge :city="city" :unlocked="is_unlocked_city(city)" />
										<city-stars :city="city" :arc="true" size="normal" empty_color="gray" />
										<!-- <div class="stars" :class="'nb-' + city.clientGames.length">
											<div v-for="game in city.clientGames" :key="game.id" class="star" :class="{ full: has_gold_medal(game) }"></div>
										</div> -->
									</div>
								</div>
							</div>
						</div>
						<div class="buttons">
							<div class="logout" v-hammer:tap="() => logout()"><ui-icon name="logout" :size="16" /> Déconnexion</div>
						</div>
					</div>
					<div class="close" v-hammer:tap="close"></div>
				</div>
			</div>


		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import CityBadge from '@/components/world/city-badge'
import CityStars from '@/components/world/city-stars'
import {getValueOnPath} from "../../js/utils/utils";

export default {
	name: 'passeport',
	data() {
		return {

		}
	},
	computed: {
		...mapGetters([ 'app', 'world', 'user' ])
	},
	methods: {
		is_unlocked_city(city) {
			if (!city.clientCriterias.length) return true
			let criteria = city.clientCriterias.find((c) => { return c.action === 'spend' && c.clientLootItem && c.clientLootItem.countable  && c.clientLootItem.slug === 'gold' })
			if (
				criteria
				&& criteria.id
				&& (getValueOnPath(this, 'user.achievement.criteriasAchieved') || [])
					.find(achieve => achieve.criteriaId === criteria.id)
			) return true
			return false
		},
		goto_city(city) {
			this.$router.push({ name: 'city', params: { city_slug: city.slug } })
		},
		has_gold_medal(game) {
			let criteria = game.clientCriterias.find((c) => {
				return c.action === 'presence' && this.user.achievement.criteriasAchieved.find((achieved) => { return achieved.criteriaId === c.id })
			})
			if (criteria) return true
			return false
		},
		// pan(e) {
		// 	let target = this.$refs.passeport
		// 	let x = e.deltaX
		// 	if (x > 0) x = 0
		// 	target.style.transform = 'translate(' + x + 'px, 0)'
		// },
		// panend(e) {
		// 	let target = this.$refs.passeport
		// 	let x = e.deltaX

		// 	target.classList.add('panning')

		// 	if (x < 0 && Math.abs(x) > target.clientWidth / 5) {
		// 		target.style.transform = 'translate(-150%, 0)'
		// 		this.close()
		// 	} else
		// 		target.style.transform = 'translate(0, 0)'

		// 	setTimeout(() => {
		// 		target.classList.remove('panning')
		// 		target.style.transform = ''
		// 	}, 250)
		// },
		logout() {
			this.$store.dispatch('removeUser')
			this.$store.dispatch('removeAccessToken')
			localStorage.removeItem('tuto-login')
			this.$router.push({ name: 'start' })
		},
		close() {
			this.$router.push({ name: 'world' })
		}
	},
	components: { CityBadge, CityStars }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile
	.passeport .passeport-content .pages .page .page-content .stamps .stamps-content
		width 100%
		flex center flex-start column
		>>> .city-badge
			marign 0 auto
			.city-stars .star
				width 20px


.passeport
	&.passeport-enter-active, &.passeport-leave-active
		transition 1s easeOutQuart
		.passeport-bg
			transition 0.25s easeOutQuart
		.passeport-content
			transition 0.5s easeOutQuart
			perspective 2000px
			.pages
				transform-style preserve-3d
				.page
					transition transform 0.5s easeOutQuart
					&:nth-child(1)
						transition-delay 0.2s
					&:nth-child(2)
						transition-delay 0.3s
					&:nth-child(3)
						transition-delay 0.4s
					&:nth-child(4)
						transition-delay 0.5s
	&.passeport-leave-active
		transition 0.5s easeOutQuart
	&.passeport-enter, &.passeport-leave-to
		.passeport-bg
			opacity 0
		.passeport-content
			transform translateX(-150%)
			.pages .page/*:nth-child(4)*/
				// transform rotateY(-72.5deg)
				transform rotateY(-180deg)
	// &.passeport-leave-to
	// 	opacity 0


.passeport
	position absolute
	left 0
	top 0
	right 0
	bottom 0
	height 100%
	width 100%
	display flex
	align-items center
	justify-content flex-start
	.passeport-bg
		position absolute
		left 0
		top 0
		bottom 0
		right 0
		background-color alpha(#000, 15%)
	.passeport-content
		width calc(100% - 24px)
		height calc(100% - 64px)
		max-height 750px
		max-width 500px
		&.panning
			transition transform 0.25s easeOutQuart
		.side
			height 100%
			width 24px
			background-color dark_blue
			border-top-right-radius 16px
		.bg
			position absolute
			left 0
			right 0
			bottom 0
			top 16px
			background-color dark_blue
			border-top-right-radius 32px
			border-bottom-right-radius 32px
		.pages
			position absolute
			left 24px
			right 4px
			bottom 4px
			top 16px
			// perspective 500px
			.page
				position absolute
				left 0
				right 0
				bottom 0
				height calc(100% - 8px)
				background-color yellow_light
				border-top-right-radius 32px
				border-bottom-right-radius 32px
				box-shadow 0px 6px 10px alpha(#000, 30%)
				transform-origin left center
				&:nth-child(2)
					bottom 3px
					right 3px
				&:nth-child(3)
					bottom 6px
					right 6px
				&:nth-child(4)
					bottom 9px
					right 9px
				.page-content
					position absolute
					left 0
					right 0
					bottom 0
					top 0
					padding 16px 24px 0 24px
					.user
						flex flex-start center
						height 40px
						margin 0 0 8px 0
						border-bottom 2px solid #D8DCCF
						color dark_blue
						white-space nowrap
						font-size 1.8rem
						line-height 24px
						text-transform uppercase
					.stamps
						height calc(100% - 120px)
						margin 0 0 16px 0
						// &.bottom-gradient:after
						// 	background linear-gradient(to top, yellow_light, transparent)
						.stamps-content
							padding-top 16px
							// height 100%
							width (((104px * 1.25) + 16px) * 2)
							margin 0 auto
							display flex
							flex-wrap wrap
							justify-content flex-start
							align-items center
							align-content center
							.stamp
								cursor pointer
							>>> .city-badge
								width (104px * 1.25)
								height (104px * 1.25)
								margin 16px 8px
								background-color yellow_light
								transition 0.25s easeOutQuart
							>>> .city-stars
								position absolute
								left 50%
								top 4px
								transform translateX(-50%)
								flex center center
								height 24px
								.star
									margin 0
									background yellow_light
									width 40px
									&.empty
										> img
											opacity 0.5

					.buttons
						text-align center
						width 100%
						.logout
							flex center center
							display inline-flex
							height 40px
							padding 0 24px
							border-radius 32px
							background-color #D85E53
							color #fff
							font-size 1.4rem
							font-weight 700
							cursor pointer
							line-height @height
							text-transform uppercase
							>>> .ui-icon
								margin 0 8px 0 0
							&:hover
								background-color darken(#D85E53, 10%)
							&:active
								background-color darken(#D85E53, 20%)
								transform translateY(2px)
		.close
			position absolute
			right -8px
			top -8px
			width 40px
			height 40px
			background dark_blue url(../../assets/img/close.svg) center center no-repeat
			background-size 16px auto
			border-radius 50%
			border 4px solid yellow_light
			cursor pointer
			&:active
				transform translateY(1px)


</style>
