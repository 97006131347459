<template>
	<div class="ui-slider">
		<div class="button minus" v-hammer:tap="() => set_value(local_value - 1)">-</div>
		<div class="bars" :class="slug">
			<div v-for="i in max" :key="i" class="bar" :class="{ full: i <= local_value, last: i === max }"></div>
			<div class="bars-over" ref="bars" v-hammer:pan="pan" v-hammer:tap="pan"></div>
		</div>
		<div class="button plus" v-hammer:tap="() => set_value(local_value + 1)">+</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sounds from '@/js/utils/sounds'

export default {
	name: 'ui-slider',
	data() {
		return {
			local_value: null
		}
	},
	props: {
		value: { default: 5 },
		min: { default: 0 },
		max: { default: 10 },
		slug: { default: 1 }
	},
	computed: {
		...mapGetters([ 'app' ])
	},
	watch: {
		local_value() {
			if (this.local_value !== this.value) {
				this.$emit('change', { slug: this.slug, value: this.local_value })
				this.$nextTick(() => {
					Sounds.play('button')
				})
			}
		}
	},
	methods: {
		pan(e) {
			const rect = this.$refs.bars.getBoundingClientRect()
			const diff = (e.center.x / 1 - rect.x)
			this.set_value(Math.ceil(diff / (rect.width / this.max)))
		},
		set_value(value) {
			if (value < this.min) value = this.min
			if (value > this.max) value = this.max
			this.local_value = value
		}
	},
	created() {
		this.local_value = this.value
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.ui-slider
	height 100%
	flex center center
	.button
		height 100%
		width 28px
		height 28px
		font-size 3.2rem
		line-height @height
		text-align center
		// background-color alpha(dark, 10%)
		border-radius 4px
		margin 0 4px
		cursor pointer
		// &:hover
		// 	background-color alpha(dark, 20%)
		&:active
			transform translateY(2px)
	.bars
		height 100%
		flex flex-start flex-start
		.bar
			width 10px
			width 6px
			background-color dark
			margin 0 5px 0 0
			height 100%
			border-radius 6px
			// flex center center
			font-size 3.2rem
			// line-height 32px
			flex center center
			// background-color black
			opacity 0.25
			&.full
				opacity 1
			&.last
				margin 0
	.bars-over
		absolute 0
		cursor pointer
		

</style>
