<template>
	<div v-if="answer" class="fill-blanks-answer" v-hammer:panstart="panstart" v-hammer:pan="pan" v-hammer:panend="panend" :class="{
		'show-result': show_result,
		'loading': loading,
		'correct': (show_result && answer.score > 0),
		'error': (show_result && answer.score === 0),
		'result-visible': result_visible,
		'selected-by-player': selected_by_player,
		'selected-by-opponent': selected_by_opponent,
		draggable
	}">
		<div v-if="selected_by_player || selected_by_opponent || (show_result && answer.score > 0)" class="selection" :class="{ player: selected_by_player, opponent: selected_by_opponent, correct: (show_result && answer.score > 0) }">
			<transition name="bg">
				<div class="bg bg-loading" v-if="show_result && loading" :key="1"></div>
			</transition>
			<transition name="bg">
				<div class="bg bg-result" v-if="show_result && !loading" :key="2" :class="{ 'correct': (answer.score > 0), 'error': (answer.score === 0) }"></div>
			</transition>
			<div class="border"></div>
			<div v-if="selected_by_player" class="avatar player"></div>
			<div v-if="selected_by_opponent" class="avatar opponent" v-html="opponent_avatar"></div>
			<div v-if="selected_by_player || selected_by_opponent || answer.score > 0" class="answer-icon"></div>
		</div>
		<div class="answer-text" v-html="answer_text" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getValueOnPath } from '@/js/utils/utils'

let dropping = false
let $drop = null
let drop_position = null
let my_position = null

export default {
	name: 'fill-blanks-answer',
	data() {
		return {
			loading: false,
			result_animation: false,
			result_visible: false
		}
	},
	props: {
		answer: { default: false },
		show_result: { default: false },
		selected_by_player: { default: false },
		selected_by_opponent: { default: false },
		draggable: { default: false }
	},
	computed: {
		...mapGetters([ 'world' ]),
		city() {
			return getValueOnPath(this, 'world.current.city')
		},
		answer_text() {
			return (
				(getValueOnPath(this, 'answer.contentTexts') || [])
					.find(x => (x.tags || []).includes('answer')) || {}
			).html || ''
		},
		opponent_avatar() {
			if (this.$route.params.is_challenge) {
				return this.$medias.find({ tags: [ 'avatar', 'challenge-avatar' ] })
			} else {
				if (this.city && this.city.slug) {
					const tags = [ this.city.slug, 'avatar' ]
					return this.$medias.find({ tags })
				}
			}
			return null
		},
	},
	watch: {
		show_result(newValue) {
			this.loading = false
			this.result_animation = false
			this.result_visible = false
			if (newValue) {
				this.loading = true
				setTimeout(() => {
					this.loading = false
					this.result_animation = true
					setTimeout(() => {
						this.result_animation = false
						this.result_visible = true
					}, 750)
				}, 750)
			}
		}
	},
	methods: {
		panstart() {
			if (this.draggable) {
				my_position = this.$el.getBoundingClientRect()
				this.$el.classList.add('dragging', 'moving')
				$drop.style.display = 'block'
			}
		},
		pan(e) {
			if (this.draggable && $drop) {
				this.$el.style.transform = 'translate(' + e.deltaX + 'px, ' + e.deltaY + 'px) scale(1.1)'
					
				if (e.deltaY < -(my_position.y - (drop_position.y + drop_position.height - 32))) {
					$drop.classList.add('droppable')
					dropping = true
				} else {
					$drop.classList.remove('droppable')
					dropping = false
				}
			}
		},
		panend(e) {
			if (this.draggable) {
				this.$el.classList.remove('dragging')
				$drop.style.display = 'none'
				$drop.classList.remove('droppable')
				if (dropping) {
					this.$el.style.transform = 'translate(' + e.deltaX + 'px, ' + e.deltaY + 'px) scale(1.75)'
					this.$el.style.opacity = 0
				} else {
					this.$el.style.transform = ''
				}
				$drop.style.display = 'none'
				setTimeout(() => {
					this.$el.classList.remove('moving')
					if (dropping) this.$emit('select')
				}, 250)
			}
		}
	},
	mounted() {
		if (this.draggable) drop_position = document.querySelector('#game .question-part').getBoundingClientRect()
		$drop = document.querySelector('#game .question-part .drop')
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.fill-blanks-answer
		font-size 1.8rem
		line-height 1em

.fill-blanks-answer
	red = #CF5757
	green = #5ED174
	width 100%
	height 100%
	padding 0 8px
	display flex
	align-items center
	justify-content center
	text-align center
	border-radius 16px
	background #fff
	box-shadow 0px 3px 6px alpha(#000, 30%)
	cursor default
	color dark
	font-size 2.2rem
	transition 0.25s easeOutQuart
	&:not(.draggable)
		opacity 0.5
	&.draggable
		position absolute
		left 0
		top 0
		cursor move
	&.dragging
		transition none
		box-shadow 0px 3px 6px alpha(#000, 30%), -8px 8px 4px alpha(#000, 30%)
		&:after
			content ''
			absolute 4px
			border 3px dashed dark
			border-radius 12px
	&.moving
		z-index 20 !important
	&.selected-by-player
		animation push 0.25s linear
	&.selected-by-opponent
		animation push2 0.25s linear
	&.selected-by-player, &.selected-by-opponent, &.show-result
		.selection
			absolute 0
			&.opponent
				.border
					border-color #006ea1
				.bg
					&.bg-loading:before
						transform translateX(100%)
					&.bg-result:before
						transform translateX(-100%)
			&.player
				.border
					border-color dark
				.bg
					&.bg-loading:before
						transform translateX(-100%)
					&.bg-result:before
						transform translateX(100%)

	&.show-result
		&:hover
			background-color transparent
		&.error
			&.selected-by-opponent
				.selection .bg.bg-result:before
					background-color #FFCECE
			&.selected-by-player
				.selection .bg.bg-result:before
					background-color red
		&.correct
			.selection .bg.bg-result:before
				background-color #afe2b8
			&.selected-by-player
				.selection .bg.bg-result:before
					background-color green

		&.result-visible
			&.correct, &.error.selected-by-player, &.error.selected-by-opponent
				color #fff
			&.error
				&.selected-by-opponent
					.selection .bg.bg-result
						background-color #FFCECE
					.selection .answer-icon
						background-image url('../../../assets/img/trivia-error-opponent.svg')
				&.selected-by-player
					.selection .bg.bg-result
						background-color red
					.selection .answer-icon
						background-image url('../../../assets/img/trivia-error-player.svg')
			&.correct
				.selection .bg.bg-result
					background-color #afe2b8
				.selection .answer-icon
					background-image url('../../../assets/img/trivia-success.svg')
				&.selected-by-player
					color #fff
					.selection .bg.bg-result
						background-color green
					.selection .answer-icon
						background-image url('../../../assets/img/trivia-success-player.svg')
			.selection .border
				border-color #fff !important

		.selection
			absolute 0
			&.correct
				// .border
				// 	border-color transparent
				.bg
					&.bg-loading:before
						transform translateX(-100%)
					&.bg-result:before
						transform translateX(100%)
			.bg.bg-loading, .bg.bg-result
				absolute 0
				overflow hidden
				border-radius 16px
				-webkit-mask-image -webkit-radial-gradient(white, black)
				&.bg-enter-active
					transition 0.75s easeOutQuart
					&:before
						transition 0.75s easeOutQuart
				&.bg-enter-to:before
					transform translateX(0)
				&:before
					content ''
					absolute 0
					// opacity 0
					background-color #ECECEB

	.selection
		.border
			absolute 4px
			border 2px solid transparent
			border-radius 16px
		.avatar
			position absolute
			width 32px
			height 32px
			&.player
				left -8px
				bottom -8px
				background url(../../../assets/img/avatars/player.svg) center center no-repeat
				background-size contain
			&.opponent
				right -8px
				top -8px
				background url(../../../assets/img/avatars/opponent.svg) center center no-repeat
				background-size contain
				>>> svg
					width 100%
					height 100%
		.answer-icon
			position absolute
			width 40px
			height 40px
			right -8px
			bottom -8px
			background center center no-repeat
			background-size contain


@keyframes push
	0%
		transform translateY(0)
	50%
		transform translateY(2px) scale(0.9)
	100%
		transform translateY(0)

@keyframes push2
	0%
		transform translateY(0)
	50%
		transform translateY(2px) scale(0.9)
	100%
		transform translateY(0)


</style>
