<template>
	<div class="save-progression">
		<div class="save-progression-bg"></div>
		<div class="save-progression-content">

			<div class="content">
				<div class="question">Aimeriez-vous créer un compte pour sauvegarder votre progression?</div>

				<div class="buttons">
					<div class="button green" v-hammer:tap="create">J’aimerais créer<br>un compte</div>
					<div class="button red" v-hammer:tap="close">Je ne veux pas<br>faire de sauvegarde</div>
				</div>

				<div class="change-account"><span v-hammer:tap="() => logout_alert = true">Changer de compte</span></div>
			</div>

			<div class="ticket-bottom">
				<div class="plane"></div>
				<div class="barcode"></div>
			</div>
		</div>

		<transition name="change-account-alert">
			<div v-if="logout_alert" class="change-account-alert">
				<div class="content">
					<div class="title">Changer de compte</div>
					<div class="main-icon"></div>
					<div class="question">Vous êtes sur le point de vous déconnecter de la session actuelle sans avoir enregistré<br>votre progression. Elle sera perdue et ne pourra pas être récupérée.</div>
					<div class="question">Pour enregistrer votre progression, appuyer sur «&nbsp;Annuler&nbsp;» ci-dessous, puis sur «&nbsp;J’aimerais créer un compte&nbsp;».</div>
					<div class="question">VOULEZ-VOUS CONFIRMER LA DÉCONNEXION?</div>
					<div class="buttons">
						<div class="button red" v-hammer:tap="logout">Quitter (sans sauvegarde)</div>
						<div class="button white" v-hammer:tap="close_alert">Annuler (retour)</div>
					</div>
				</div>
			</div>
		</transition>

	</div>
</template>

<script>
export default {
	name: 'save-progression',
	data() {
		return {
			logout_alert: false
		}
	},
	methods: {
		create() {
			this.$router.push({ name: 'signup' })
		},
		logout() {
			this.$store.dispatch('removeUser')
			// this.$store.dispatch('removeAccessToken')
			localStorage.removeItem('tuto-login')
			this.$router.push({ name: 'start' })
		},
		close() {
			this.$router.push({ name: 'world' })
		},
		close_alert() {
			this.logout_alert = false
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'

.save-progression
	&.save-progression-enter, &.save-progression-leave-to
		opacity 0
		.save-progression-content
			transform translate(-50%, -65%)

.save-progression
	absolute 0
	height 100%
	width 100%
	flex center center
	transition 0.25s easeOutQuart
	user-select none
	.save-progression-bg
		absolute -60px
		color1 = alpha(#111, 50%)
		color2 = alpha(#222, 50%)
		background-image linear-gradient(135deg, color1 25%, color2 25%, color2 50%, color1 50%, color1 75%, color2 75%, color2 100%)
		background-size 60px 60px
		animation bg 3s linear infinite
	.save-progression-content
		position absolute
		left 50%
		top 50%
		transform translate(-50%, -50%)
		width calc(100% - 48px)
		max-width 340px
		background dark
		border-radius 24px
		transition 0.25s easeOutQuart
		color #fff
		box-shadow 0px 10px 6px alpha(#000, 50%)

		.content
			padding 32px

			.question
				margin 0 0 32px 0
				font-size 2.3rem
				line-height 1.2em
				font-weight 400
				text-transform uppercase
				text-align center

			.buttons
				flex center center
				flex-direction column
				.button
					height 64px
					min-width 250px
					font-size 1.8rem
					line-height 1.2em
					margin 0 0 16px 0
					box-shadow 0px 3px 1px alpha(#000, 30%)
					flex center center
					border-radius 32px
					padding 0 32px
					color #fff
					text-transform uppercase
					text-align center
					cursor pointer
					&.green
						green = #5BA963
						background-color green
						&:hover, &:active
							background-color darken(green, 10%)
					&.red
						red = #CE5555
						background-color red
						&:hover, &:active
							background-color darken(red, 10%)
					&:active
						transform translateY(3px)
						box-shadow none
					&:last-child
						margin 0
			.change-account
				flex center center
				min-width 250px
				margin 64px 0 0 0
				padding 12px 32px
				background #2C313C
				box-shadow 0px 3px 1px alpha(#000, 30%)
				border-radius 32px
				font-size 1.8rem
				line-height 1.2em
				color #fff
				text-transform uppercase
				text-align center
				cursor pointer
				&:hover, &:active
					background-color darken(#2C313C, 10%)
				&:active
					transform translateY(3px)
					box-shadow none

		.ticket-bottom
			// position absolute
			// left 0
			// right 0
			// bottom 0
			width 100%
			height 120px
			border-top 5px dotted alpha(gray, 50%)
			display flex
			align-items center
			justify-content center
			flex-direction column
			opacity 0.5
			.plane
				width 24px
				height 24px
				margin 0 0 8px 0
				background url(../../assets/img/plane.svg) center center no-repeat
				background-size auto 100%
			.barcode
				width 100%
				height 48px
				background url(../../assets/img/barcode.svg) center center no-repeat
				background-size auto 100%

	.change-account-alert
		absolute 0
		background-color alpha(#ccc, 75%)
		flex center center
		transition 0.25s easeOutQuart
		user-select none
		&.change-account-alert-enter, &.change-account-alert-leave-to
			opacity 0
			.content
				transform translateY(-24px)
		> .content
			background-color dark
			width 330px
			padding 24px 16px
			text-align center
			color #fff
			border-radius 8px
			box-shadow 0 0 8px 2px alpha(#000, 50%)
			transition 0.25s easeOutQuart
			.title
				margin 0 0 16px 0
				font-size 2rem
				line-height 1.3em
				text-transform uppercase
			.main-icon
				width 100%
				height 48px
				margin 0 0 16px 0
				background url(../../assets/img/passeport-notification.svg) center center no-repeat
				background-size contain
				>>> svg
					width 64px
					height 64px
			.question
				margin 0 0 16px 0
				font-size 1.6rem
				line-height 1.2em
			.buttons
				padding 16px 0
				flex center center
				flex-direction column
				.button
					height 48px
					width 100%
					font-size 1.8rem
					line-height 1em
					margin 0 0 16px 0
					box-shadow 0px 3px 1px alpha(#000, 30%)
					flex center center
					border-radius 32px
					padding 0 16px
					color dark
					// text-transform uppercase
					text-align center
					cursor pointer
					&.white
						background-color #f5f5f5
						&:hover, &:active
							background-color darken(#f5f5f5, 10%)
					&.red
						background-color #CE5555
						color #fff
						&:hover, &:active
							background-color darken(#CE5555, 10%)
					&:active
						transform translateY(3px)
						box-shadow none
					&:last-child
						margin 0

@keyframes bg
	from
		transform translateX(0)
	to
		transform translateX(60px)



</style>
