<template>
	<div class="matchable-element" :class="[{ active: matchable_element.active, checked: matchable_element.checked, error }]" v-hammer:tap="() => toggle_active()">
		<div class="matchable-element-content">

			<transition name="flip" mode="out-in">
				<div v-if="!flipped" class="face front" key="front">
					<ui-text-content v-if="matchable_element.item && matchable_element.item.html" :html="matchable_element.item.html" />
					<transition name="check">
						<div v-if="matchable_element.checked" class="check-icon">
							<svg xmlns="http://www.w3.org/2000/svg" width="35.687" height="34.43" viewBox="0 0 35.687 34.43">
								<g transform="translate(-1238.832 -707.483)" fill="#fff">
									<path d="M 1254.126342773438 740.41357421875 C 1252.786865234375 740.41357421875 1251.50732421875 739.8400268554688 1250.615600585938 738.8399658203125 L 1241.948364257813 729.1196899414063 L 1240.9501953125 728.0001220703125 L 1242.069702148438 727.0018310546875 L 1246.852905273438 722.7368774414063 L 1247.972534179688 721.7385864257813 L 1248.970825195313 722.8582153320313 L 1253.390991210938 727.8157348632813 L 1263.627685546875 710.826904296875 L 1264.40185546875 709.5421752929688 L 1265.6865234375 710.3162841796875 L 1271.175537109375 713.6234130859375 L 1272.46044921875 714.3975219726563 L 1271.686279296875 715.682373046875 L 1258.156005859375 738.1372680664063 C 1257.388061523438 739.4119262695313 1256.044921875 740.25537109375 1254.563232421875 740.3934326171875 C 1254.422485351563 740.40673828125 1254.274780273438 740.41357421875 1254.126342773438 740.41357421875 Z"/>
									<path fill="currentColor" d="M 1254.126342773438 738.91357421875 C 1254.225463867188 738.91357421875 1254.324584960938 738.9093017578125 1254.424072265625 738.89990234375 C 1255.437377929688 738.8054809570313 1256.345825195313 738.235107421875 1256.871215820313 737.3630981445313 L 1270.401489257813 714.908203125 L 1264.912475585938 711.60107421875 L 1253.625610351563 730.332763671875 L 1247.851196289063 723.8564453125 L 1243.067993164063 728.1213989257813 L 1251.735107421875 737.8416748046875 C 1252.346069335938 738.52685546875 1253.217529296875 738.91357421875 1254.126342773438 738.91357421875 M 1254.126342773438 741.91357421875 C 1252.359741210938 741.91357421875 1250.671997070313 741.1571655273438 1249.495971679688 739.8382568359375 L 1240.828857421875 730.117919921875 L 1238.832275390625 727.8787841796875 L 1241.071411132813 725.8822631835938 L 1245.854614257813 721.6173095703125 L 1248.093872070313 719.6207275390625 L 1250.09033203125 721.8599243164063 L 1253.156372070313 725.2986450195313 L 1262.342895507813 710.052734375 L 1263.89111328125 707.4832763671875 L 1266.460693359375 709.0314331054688 L 1271.94970703125 712.3385620117188 L 1274.519409179688 713.8868408203125 L 1272.971069335938 716.45654296875 L 1259.440795898438 738.911376953125 C 1258.427978515625 740.5924682617188 1256.656494140625 741.7048950195313 1254.702392578125 741.886962890625 C 1254.516845703125 741.9044799804688 1254.32177734375 741.91357421875 1254.126342773438 741.91357421875 Z"/>
								</g>
							</svg>
						</div>
					</transition>
				</div>
				<div v-if="flipped" class="face back" key="back">
					
				</div>
			</transition>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import UiTextContent from '@/components/ui/ui-text-content'

export default {
	name: 'matchable-element',
	data() {
		return {
			error: false,
			flipped: false
		}
	},
	props: {
		matchable_element: { default: null }
	},
	watch: {
		'matchable_element.error'(newValue, oldValue) {
			if (newValue) {
				this.error = true
				setTimeout(() => {
					this.error = false
				}, 500)
			}
		},
		'matchable_element.checked'(newValue, oldValue) {
			setTimeout(() => {
				this.flipped = newValue
			}, 800)
		}
	},
	methods: {
		toggle_active() {
			this.$emit('toggle-active', { matchable_element: this.matchable_element })
		}
	},
	mounted() {
		this.flipped = this.matchable_element.checked
	},
	components: { UiTextContent }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


// #app.not-touch
// 	.game
// 		.matchable-element
// 			&:hover .face.front
// 				background-color alpha(#fff, 80%)


.game
	.matchable-element
		flex center center
		font-size 2.8rem
		line-height 32px
		text-align center
		cursor pointer
		perspective 500px
		// transition 0.1s easeOutQuart
		&.active .matchable-element-content .face.front, &.checked .matchable-element-content .face.front
			border-color #fff
			background alpha(#000, 20%)
			color #fff
		&.error .matchable-element-content .face.front
			border-color red
			background-color lighten(red, 75%)
			animation shake 0.5s linear infinite
			color red
		
		.matchable-element-content
			width 100%
			height 100%
			transition transform 0.8s
			transform-style preserve-3d
			
			.face
				absolute 0
				flex center center
				&.front
					padding 8px
					border-radius 8px
					background #fff
					border 3px solid transparent
					box-shadow 0 6px 0 0 alpha(#000, 20%)
					.check-icon
						position absolute
						top -12px
						right -12px
						width 36px
						height 36px
						background url(../../../assets/img/check.svg) center center no-repeat
						background-size contain
						&.check-enter-active, &.check-leave-active
							transition 0.35s easeOutBack
						&.check-enter, &.check-leave-to
							transform translateY(-40px) scale(1.5)
							opacity 0
				&.back
					background alpha(#fff, 50%) url(../../../assets/img/logo-white.svg) center center no-repeat
					background-size auto 80%
					border-radius 8px
					opacity 0.25
					cursor default
				&.flip-enter-active, &.flip-leave-active
					transition 0.2s linear
				&.flip-enter
					transform rotateY(-90deg)
				&.flip-leave-to
					transform rotateY(90deg)
					

@keyframes shake
	10%, 90%
		transform translate3d(-1px, 0, 0)
	20%, 80%
		transform translate3d(2px, 0, 0)
	30%, 50%, 70%
		transform translate3d(-4px, 0, 0)
	40%, 60%
		transform translate3d(4px, 0, 0)



</style>
