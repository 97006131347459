<template>
	<div v-if="slides" class="carousel">
		<hooper class="carousel" :itemsToShow="1.25" :infiniteScroll="true" :centerMode="true">
			<slide v-for="slide in slides" :key="slide.id" class="slide">
				<div v-if="slide.html" class="slide-content" :class="{ loading: loading }">
					<div class="text" v-html="slide.html"></div>
				</div>
				<div v-if="slide.url" class="slide-content" :class="{ loading: loading }" v-hammer:tap="()=> zoom_image(slide)">
					<img :src="slide.url" @load="add_loaded(slide.url)">
					<div v-if="slide.document && slide.document.title" class="legend" v-html="slide.document.title"></div>
				</div>
			</slide>
			<hooper-pagination class="pagination" slot="hooper-addons"></hooper-pagination>
		</hooper>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'carousel',
	data() {
		return {
			images: [],
			loading: true
		}
	},
	props: [ 'slides' ],
	computed: {
		...mapGetters([ 'game' ]),
		nb_medias() {
			let slides = this.slides.filter((s) => { return s.url })
			return slides.length
		}
	},
	watch: {
		images() {
			if (this.images.length >= this.nb_medias) {
				this.loading = false
				this.$emit('loaded')
			}
		}
	},
	methods: {
		add_loaded(url) {
			if (!this.images.includes(url)) {
				this.images.push(url)
			}
		},
		zoom_image(slide) {
			this.$store.dispatch('edit_game_ui', { zoomed_slide: slide })
		},
		init() {
			this.images = []
			this.loading = true
		}
	},
	mounted() {
		this.init()
	},
	components: { Hooper, Slide, HooperPagination }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'

.carousel
	width 100%
	height calc(100% + 10px)
	outline none
	.slide
		height 100%
		width 300px
		// padding 0 10px
		.slide-content
			margin 0 auto
			height calc(100% - 32px)
			width calc(100% - 20px)
			background-size cover
			background-position center center
			background-repeat no-repeat
			border-radius 15px
			overflow hidden
			font-size 2rem
			&.loading
				background-color alpha(#fae9eb, 10%)
				.text, .legend, > img
					opacity 0
				&:after
					background-color alpha(blue, 50%)
					background url(../../../assets/img/button-loader.svg) center center no-repeat
					background-size 20% auto
			&:after
				content ''
				position absolute
				left 0
				top 0
				right 0
				bottom 0
			.text
				height 100%
				display flex
				align-items center
				justify-content center
				text-align center
				padding 0 32px
				&:before, &:after
					content ''
					position absolute
					top 0
					bottom 0
					width 20px
					height 100%
					background-color alpha(#fff, 50%)
					background-color #fae9eb
				&:before
					left 0
					border-top-left-radius 15px
					border-bottom-left-radius 15px
				&:after
					right 0
					border-top-right-radius 15px
					border-bottom-right-radius 15px
			> img
				position absolute
				left 0
				top 0
				width 100%
				height 100%
				object-fit cover
				border-radius 15px
			.legend
				position absolute
				left 0
				right 0
				bottom 0
				display flex
				align-items center
				justify-content center
				text-align center
				padding 4px 8px
				background-color #fae9eb
				color red
				border-bottom-left-radius 15px
				border-bottom-right-radius 15px
	.pagination
		position absolute
		left 0
		bottom 0
		padding 0
		height 32px
		display flex
		// align-items center
		justify-content center
		>>> .hooper-indicators
			height 100%
			> li
				height 100%
				display flex
				align-items center
				.hooper-indicator
					width 10px
					height 10px
					margin 0 3px
					border-radius 50%
					opacity 0.5
					background-color #fff
					&.is-active
						opacity 1
						background-color #fff

</style>
