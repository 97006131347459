<template>
	<ui-button
		v-if="game"
		class="game-button"
		:class="{ 'is-loading': loading }"
		v-hammer:tap="()=> launch_game()"
		:inactive="!active"
		:loading="loading"
	>
		{{ game.document.title }}
		<div v-if="medal && !force_tuto" class="medal"><img :src="medal" alt="Médaille"></div>
	</ui-button>
</template>

<script>
import { mapGetters } from 'vuex'
import Sounds from '@/js/utils/sounds'

export default {
	name: 'game-button',
	data() {
		return {
			loading: false
		}
	},
	props: {
		city: { default: null },
		game: { default: null },
		force_tuto: { default: false }
	},
	computed: {
		...mapGetters([ 'app', 'world', 'user' ]),
		active() {
			if (!this.game.clientCriterias.length) return true
			let criteria = this.game.clientCriterias.find((c) => {
				return c.action === 'presence' && this.user.achievement.criteriasAchieved.find((achieved) => { return achieved.criteriaId === c.id })
			})
			if (criteria) return true
			return false
		},
		medal() {
			const medals = [ 'gold', 'silver', 'bronze' ]
			const medal = this.user.achievement.rewardsAchieved.filter((r) => {
				return r.clientReward &&
					r.clientReward.rewardableElementId === this.game.id &&
					r.status === 'completed' &&
					r.clientReward.clientLootItem &&
					r.clientReward.clientLootItem.tags.includes('medal')
			})
				.map(x => x.clientReward.clientLootItem.tags)
				.map(tags => tags.reduce((a, tag) => medals.includes(tag) ? tag : a, null))
				.reduce((a, medal) => {
					a[medals.indexOf(medal)] = medal
					return a
				}, [])
				.reduce((a, medal) => (a ? a : (medal ? medal : a)), null)
			return this.$medias.find({ tags: [ 'medal', medal ] })
		}
	},
	methods: {
		launch_game() {
			if (this.active && !this.loading) {
				Sounds.play('button')
				this.loading = true
				if (this.force_tuto) {
					let slug = this.game.tags.find((t) => [ 'trivia_game', 'matching_game' ].includes(t))
					if (slug) {
						let tuto = this.user.achievement.tutorialJournal.find((t) => t.tutorialId === slug)
						if (tuto) this.$store.commit('EDIT_TUTORIAL_JOURNAL', { tuto, status: 'not-done' })
					}
				}
				this.$router.push({ name: 'game', params: { city_slug: this.world.current.city.slug, game_id: this.game.id } })
				// this.$router.push({ name: 'game', params: { city_slug: this.world.current.city.slug, game_id: '60ee901d59f17800069b1c94' } })
				setTimeout(() => {
					this.loading = false
				}, 3000)
			}
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


.game-button
	display block
	width calc(100% - 64px)
	margin 0 0 16px 0
	text-transform uppercase
	font-weight 700
	&.inactive
		>>> .medal
			display none
	&.is-loading
		>>> .medal
			z-index 1
	>>> .medal
		position absolute
		right -16px
		top 0px
		width 56px
		height 56px
		background center center no-repeat
		background-size auto 100%
		transition 0.25s easeOutQuart
		> img
			display block
			width 56px
			height 56px
			
		// &.bronze
		// 	background-image url(../../assets/img/medal-bronze.svg)
		// &.silver
		// 	background-image url(../../assets/img/medal-silver.svg)
		// &.gold
		// 	background-image url(../../assets/img/medal-gold.svg)

</style>