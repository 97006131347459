var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.answer)?_c('div',{directives:[{name:"hammer",rawName:"v-hammer:panstart",value:(_vm.panstart),expression:"panstart",arg:"panstart"},{name:"hammer",rawName:"v-hammer:pan",value:(_vm.pan),expression:"pan",arg:"pan"},{name:"hammer",rawName:"v-hammer:panend",value:(_vm.panend),expression:"panend",arg:"panend"}],staticClass:"fill-blanks-answer",class:{
	'show-result': _vm.show_result,
	'loading': _vm.loading,
	'correct': (_vm.show_result && _vm.answer.score > 0),
	'error': (_vm.show_result && _vm.answer.score === 0),
	'result-visible': _vm.result_visible,
	'selected-by-player': _vm.selected_by_player,
	'selected-by-opponent': _vm.selected_by_opponent,
	draggable: _vm.draggable
}},[(_vm.selected_by_player || _vm.selected_by_opponent || (_vm.show_result && _vm.answer.score > 0))?_c('div',{staticClass:"selection",class:{ player: _vm.selected_by_player, opponent: _vm.selected_by_opponent, correct: (_vm.show_result && _vm.answer.score > 0) }},[_c('transition',{attrs:{"name":"bg"}},[(_vm.show_result && _vm.loading)?_c('div',{key:1,staticClass:"bg bg-loading"}):_vm._e()]),_c('transition',{attrs:{"name":"bg"}},[(_vm.show_result && !_vm.loading)?_c('div',{key:2,staticClass:"bg bg-result",class:{ 'correct': (_vm.answer.score > 0), 'error': (_vm.answer.score === 0) }}):_vm._e()]),_c('div',{staticClass:"border"}),(_vm.selected_by_player)?_c('div',{staticClass:"avatar player"}):_vm._e(),(_vm.selected_by_opponent)?_c('div',{staticClass:"avatar opponent",domProps:{"innerHTML":_vm._s(_vm.opponent_avatar)}}):_vm._e(),(_vm.selected_by_player || _vm.selected_by_opponent || _vm.answer.score > 0)?_c('div',{staticClass:"answer-icon"}):_vm._e()],1):_vm._e(),_c('div',{staticClass:"answer-text",domProps:{"innerHTML":_vm._s(_vm.answer_text)}})]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }